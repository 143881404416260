<template>
  <div class="pr-3">
    <v-chip
      v-if="showSingle"
      label
      class="badge-box"
      :class="type+'-share'"
    >
      {{ badge }}
    </v-chip>
    <div v-else>
      <v-chip
        v-for="(item, key) of computedBadgeList"
        :key="key"
        :color="item.isActive ? item.type+'-share' : 'disabled-share'"
        label
        class="badge-box"
      >
        {{ item.prefix }}
      </v-chip>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ShareTermBadge',
    props: {
      typeList: {
        type: Array,
        default: () => [],
      },
      showSingle: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: '',
      },
      badgeList: {
        type: Array,
        default: () => [],
        required: false,
      },
    },
    computed: {
      badge () {
        if (this.type === this.CONSTS.SHARE_TERM_TYPES.COMMON) {
          return this.CONSTS.SECURITIES_PREFIX.COMMON
        } else if (this.type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
          return this.CONSTS.SECURITIES_PREFIX.PREFERRED
        } else if (this.type === this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) {
          return this.CONSTS.SECURITIES_PREFIX.CONVERTIBLE
        } else if (this.type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT) {
          return this.CONSTS.SECURITIES_PREFIX.DEBT
        } else if (this.type === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE) {
          return this.CONSTS.SECURITIES_PREFIX.SAFE
        } else if (this.type === this.CONSTS.OPTIONS_TYPE.ISO || this.type === this.CONSTS.OPTIONS_TYPE.NSO || this.type === this.CONSTS.OPTIONS_TYPE['ISO/NSO']) {
          return this.CONSTS.SECURITIES_PREFIX.OPTION
        } else {
          return this.CONSTS.SECURITIES_PREFIX.WARRANT
        }
      },
      computedBadgeList () {
        const badgeListToDisplay = this.badgeList?.length > 0 ? JSON.parse(JSON.stringify(this.badgeList)) : JSON.parse(JSON.stringify(this.CONSTS.ALL_SECURITIES_PREFIX_LIST))
        badgeListToDisplay.forEach((obj) => {
          for (const item of this.typeList) {
            if (obj.type === item.type) {
              obj.isActive = true
              break
            }
          }
        })
        return badgeListToDisplay
      },
    },
  }
</script>
<style lang="scss" scoped>
  .badge-box {
    padding: 8px;
    height: 25px;
  }
  .common-share {
    background: #4a90e2 !important;
    color: #ffffff;
  }
  .preferred-share {
    background: #fcaf22 !important;
    color: #ffffff;
  }
  .warrant-share {
    background: #c40847 !important;
    color: #ffffff;
  }
  .disabled-share {
    background: #E5E5E5 !important;
    color: #000000;
  }
  .safe-share {
    background: #08415c !important;
    color: #ffffff;
  }
  .debt-share {
    background: #08415c !important;
    color: #ffffff;
  }
  .iso-share, .nso-share, .iso_nso-share, .option-share {
    background: #009B72 !important;
    color: #ffffff;
  }
  .convertible-share {
    background: #08415C !important;
    color: #ffffff;
  }
</style>
