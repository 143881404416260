<template>
  <v-container>
    <v-row>
      <common-trs-btn
        v-if="convertibleTermData.length>0"
        v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM"
        type="primary"
        large
        class="white--text ml-auto mr-3"
        text
        :to="newConvertibleTermRoute"
      >
        <v-icon class="mr-2">
          mdi-plus-circle
        </v-icon>
        New Convertible Term
      </common-trs-btn>
    </v-row>
    <common-page-loader
      v-if="loading"
    />
    <card-view
      v-for="(item, key) of convertibleTermData"
      :key="key"
      :details="item.summaryData"
      :is-editable="isEditAllowed && !item.is_published"
      :is-deleteable="isDeleteAllowed"
      :delete-title="'Delete Convertible Terms'"
      :delete-message="'Are you sure you want to delete these convertible terms?'"
      :delete-note="'Note: terms cannot be deleted if they are linked to securities or other terms.'"
      @edit="editConvertibleTerm(item)"
      @delete="removeItem(item)"
    >
      <template v-slot:card-title>
        <share-term-badge
          class="mr-1"
          :type="item.convertible_type"
          :show-single="true"
        />
        {{ item.name }}
      </template>
      <template v-slot:card-caption-title>
        Convertible Type:
      </template>
      <template v-slot:card-caption-data>
        {{ item.convertible_type === CONSTS.CONVERTIBLE_TERM_TYPES.SAFE ? CONSTS.CONVERTIBLE_TERM_TYPES_TITLE.SAFE : CONSTS.CONVERTIBLE_TERM_TYPES_TITLE.DEBT }}
      </template>
      <template v-slot:created-on-date>
        {{ $date(item.inserted_at) }}
      </template>
    </card-view>

    <no-data-msg
      v-if="convertibleTermData.length===0 && !loading"
      :route-detail="isCreateAllowed ? newConvertibleTermRoute : null"
    >
      <template v-slot:msg>
        No convertible term found.
      </template>
      <template v-slot:action-btn-label>
        Create a Convertible Term
      </template>
      <template v-slot:tooltip-msg>
        Create New Convertible Term
      </template>
    </no-data-msg>
  </v-container>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import CardView from '@/components/common/captable/components/CardView'
  import NoDataMsg from '@/components/common/captable/components/NoDataMsg'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'ConvertibleTermTab',

    components: {
      CardView,
      NoDataMsg,
      ShareTermBadge,
    },

    data: () => ({
      convertibleTermData: [],
      loading: false,
      newConvertibleTermRoute: { name: 'NewConvertibleTerm' },
      shareTermList: [],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isEditAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY_TERM, this.currentRoles)
      },
      isDeleteAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY_TERM, this.currentRoles)
      },
      isCreateAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM, this.currentRoles)
      },
    },

    watch: {
      convertibleTermData: {
        handler (val) {
          if (val) {
            this.updateSummaryData(val)
          }
        },
        immediate: true,
      },
    },

    async mounted () {
      await this.loadShareTerms()
      await this.loadData()
    },

    methods: {
      async loadShareTerms () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          if (resp && resp.data) {
            this.shareTermList = resp.data.share_terms
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },
      async loadData () {
        try {
          this.loading = true
          const resp = await captableService.getConvertibleTermList(this.captableId)
          if (resp && resp.data) {
            this.convertibleTermData = resp.data.convertible_terms
          }
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      updateSummaryData (data) {
        data.forEach((item) => {
          const obj = {
            'Valuation Cap': this.$currencyWithPrecision((item.valuation_cap)),
            'Conversion Discount': ((item.conversion_discount) * 100).toFixed(2) + '%',
            'Conversion Trigger': this.$currencyWithPrecision(item.conversion_trigger),
          }
          if (item.interest_terms) {
            obj.Interest = `${(item.interest_terms.rate * 100).toFixed(2)} % ${item.interest_terms.type}`
            const interstPeriod = this.CONSTS.INTEREST_PERIOD.find(o => o.value === item.interest_terms.period)
            obj['Interest accrued'] = interstPeriod.title
          }
          obj['Board Approval Date'] = item.board_approved_on ? this.$date(item.board_approved_on) : 'NA'
          const keys = Object.keys(obj)
          const details = []
          keys.forEach((key) => {
            details.push({
              title: key,
              value: obj[key],
            })
          })
          item.summaryData = [{
            label: 'Summary',
            data: details,
            type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS,
            id: item.id,
          }]
          const linkedShareTermData = this.getLinkedShareTerm(item)
          if (linkedShareTermData && linkedShareTermData.length > 0) {
            item.summaryData.push({
              label: 'Linked Share Term',
              data: linkedShareTermData,
            })
          }
        })
      },
      getLinkedShareTerm (item) {
        const linkedShareTerm = this.shareTermList.find(s => s.id === item.share_terms_id)
        const itemDetails = []
        if (linkedShareTerm) {
          const obj = {
            'Share Term Name': linkedShareTerm.name,
            'Board Approval Date': this.$date(linkedShareTerm.board_approved_on),
          }

          const keys = Object.keys(obj)
          keys.forEach((key) => {
            itemDetails.push({
              title: key,
              value: obj[key],
            })
          })
        }
        return itemDetails
      },
      editConvertibleTerm (item) {
        const route = { name: 'EditConvertibleTerm', query: { id: item.id } }
        this.$router.push(route)
      },
      async removeItem (itemData) {
        try {
          this.loading = true
          await captableService.deleteConvertibleTermById(itemData.cap_table_id, itemData.id)
          this.$store.dispatch('app/message', { text: 'Convertible Term deleted successfully.' })
          this.loadData()
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: err.response && err.response.status === 400 ? 'Sorry, these convertible terms cannot be deleted. Please unlink any securities associated with these terms.' : 'Sorry, an error occurred try again.',
          })
        }
      },
    },
  }
</script>
