<template>
  <common-trs-page-container :padding="0">
    <div
      class="d-flex "
    >
      <common-trs-card class="mt-n4 pb-0 mb-2">
        <common-trs-page-nav-vertical
          v-model="currentTab"
        >
          <v-tab
            key="shareTerms"
          >
            Share Terms
          </v-tab>
          <v-tab
            key="warrantTerms"
          >
            Warrant Terms
          </v-tab>
          <v-tab
            key="convertibleTerms"
          >
            Convertible Terms
          </v-tab>
        </common-trs-page-nav-vertical>
      </common-trs-card>
      <div class="flex-fill mx-6 mx-md-10">
        <v-tabs-items
          v-model="currentTab"
          class="transparent-bg"
        >
          <v-tab-item
            key="shareTerms"
            :eager="true"
          >
            <share-term-tab />
          </v-tab-item>
          <v-tab-item
            key="warrantTerms"
            :eager="true"
          >
            <warrant-term-tab />
          </v-tab-item>
          <v-tab-item
            key="convertibleTerms"
            :eager="true"
          >
            <convertible-term-tab />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </common-trs-page-container>
</template>
<script>
  import ShareTermTab from './components/ShareTermTab.vue'
  import ConvertibleTermTab from './components/ConvertibleTermTab.vue'
  import WarrantTermTab from './components/WarrantTermTab.vue'

  export default {
    name: 'ShareTermIndex',

    components: {
      ShareTermTab,
      ConvertibleTermTab,
      WarrantTermTab,
    },

    data: () => ({
      currentTab: 0,
    }),

    mounted () {
      this.currentTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0
    },
  }
</script>

<style lang="scss" scoped>
  .v-tabs.v-tabs--vertical {
    min-width: 225px !important;
    height: 100%;
  }
  ::v-deep .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tabs {
    padding-top: 20px!important;
    .v-tab {
      height: 41px;
      font-size: 0.875rem !important;
      font-weight: 400;
      margin: 5px 0px;
      &:hover {
        color: var(--v-primary-base) !important;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
      &.v-tab--active {
        font-weight: 600;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
    }
  }
</style>
