<template>
  <v-container>
    <v-row>
      <common-trs-btn
        v-if="shareTermData.length>0"
        v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM"
        type="primary"
        large
        class="white--text ml-auto mr-3"
        text
        :to="{ name: 'NewShareTerm' }"
      >
        <v-icon class="mr-2">
          mdi-plus-circle
        </v-icon>
        New Share Term
      </common-trs-btn>
    </v-row>
    <common-page-loader
      v-if="loading"
    />
    <share-term-list
      :share-term-data="shareTermData"
      @reload="loadData"
    />
    <no-data-msg
      v-if="shareTermData.length===0 && !loading"
      :route-detail="isCreateAllowed ? newShareTermRoute : null"
    >
      <template v-slot:msg>
        No share terms found.
      </template>
      <template v-slot:action-btn-label>
        Create a Share Term
      </template>
      <template v-slot:tooltip-msg>
        Create New Share Term
      </template>
    </no-data-msg>
  </v-container>
</template>
<script>
  import ShareTermList from './List'
  import * as captableService from '@/components/common/captable/services/captable'
  import NoDataMsg from '@/components/common/captable/components/NoDataMsg'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'ShareTermTab',

    components: {
      ShareTermList,
      NoDataMsg,
    },

    data: () => ({
      shareTermData: [],
      loading: false,
      newShareTermRoute: { name: 'NewShareTerm' },
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isCreateAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM, this.currentRoles)
      },
    },

    mounted () {
      this.loadData()
    },

    methods: {
      async loadData () {
        try {
          this.loading = true
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTermData = resp.data.share_terms
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
    },
  }
</script>
