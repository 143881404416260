<template>
  <v-container class="pa-0">
    <card-view
      v-for="(item, key) of shareTermData"
      :key="key"
      :details="item.details"
      :is-editable="isEditAllowed && !item.is_published"
      :is-deleteable="isDeleteAllowed"
      :delete-title="'Delete Share Terms'"
      :delete-message="'Are you sure you want to delete these share terms?'"
      :delete-note="'Note: terms cannot be deleted if they are linked to securities or other terms.'"
      @edit="routeToEdit(item)"
      @delete="removeItem(item)"
    >
      <template v-slot:card-title>
        <share-term-badge
          :show-single="true"
          :type="item.type"
        />
        {{ item.name }}
      </template>
      <template v-slot:card-caption-title>
        Share Terms:
      </template>
      <template v-slot:card-caption-data>
        {{ item.type }}
      </template>
      <template v-slot:created-on-date>
        {{ $date(item.inserted_at) }}
      </template>
    </card-view>
  </v-container>
</template>
<script>
  import CardView from '@/components/common/captable/components/CardView'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'
  import * as captableService from '@/components/common/captable/services/captable'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'ShareTermList',

    components: {
      CardView,
      ShareTermBadge,
    },

    props: {
      shareTermData: {
        type: Array,
      },
    },

    computed: {
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isEditAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY_TERM, this.currentRoles)
      },
      isDeleteAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY_TERM, this.currentRoles)
      },
    },

    watch: {
      shareTermData: {
        handler (val) {
          if (val) {
            this.updateSummaryData(val)
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateSummaryData (data) {
        data.forEach((item) => {
          item.details = [{
            label: 'Summary',
            id: item.id,
            type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS,
            data: [{
              title: 'Voting Power',
              value: parseFloat(item.voting_power).toFixed(2),
            }, {
              title: 'Board Approval Date',
              value: this.$date(item.board_approved_on),
            }],
          }]
          if (item.type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
            item.details[0].data.push({
              title: 'Conversion ratio',
              value: item.preferred_share_terms.conversion_ratio,
            })
          }
          const prefTermData = this.getPreferredTermDetails(item)
          if (prefTermData) {
            item.details.push({
              label: 'Liquidation Preferences',
              data: prefTermData,
            })
          }
        })
      },

      getPreferredTermDetails (item) {
        if (item.preferred_share_terms && item.preferred_share_terms.liquidation_preference) {
          const data = item.preferred_share_terms.liquidation_preference
          const obj = {
            Multiple: data.multiple,
            'Previous Class': data.previous_class,
            Dividends: this.$currencyWithPrecision(data.dividends),
          }
          if (data.interest_terms) {
            obj.Dividend = parseFloat(data.interest_terms.rate).toFixed(2) + ' % ' + data.interest_terms.type
            const interstPeriod = this.CONSTS.INTEREST_PERIOD.find(o => o.value === data.interest_terms.period)
            obj['Interest accrued'] = interstPeriod.title
          }
          if (data.participating_preference) {
            obj['Participating Cap'] = (data.participating_preference.participating_cap || 0) + 'x'
          }
          const keys = Object.keys(obj)
          const itemDetails = []
          keys.forEach((key) => {
            itemDetails.push({
              title: key,
              value: obj[key],
            })
          })
          return itemDetails
        }
      },
      routeToEdit (item) {
        const route = { name: 'EditShareTerm', query: { id: item.id } }
        this.$router.push(route)
      },
      async removeItem (itemData) {
        try {
          this.loading = true
          const resp = await captableService.deleteShareTermById(itemData.cap_table_id, itemData.id)
          this.singleFinacingRoundData = resp.data
          this.$store.dispatch('app/message', { text: 'Share Term deleted successfully.' })
          this.$emit('reload')
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: err.response && err.response.status === 400 ? 'Sorry, these share terms cannot be deleted. Please unlink any terms or securities associated with these terms.' : 'Sorry, an error occurred try again.',
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .card-item {
    border-radius: 0px;
    border: 0px;
    border-top: 3px solid rgba(0, 0, 0, 0.12);
  }
  .response {
    border-left: 5px solid #D7E0E2;
  }
</style>
